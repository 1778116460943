.grid-dropdown {
    p {
        display: inline-flex;
        padding-right: 10px;
    }
    .multiselect__single,
    .multiselect,
    .multiselect__tags {
        font-size: $size-7;
    }
    .multiselect {
        width: max-content;
    }
    .multiselect__content-wrapper {
        width: max-content;
    }
     .multiselect__option {
        width: 100%;
    }
    .multiselect,
    .multiselect__tags {
        height: 2.5em !important;
    }
    .multiselect__placeholder {
        margin-bottom: 0;
    }
    .multiselect__tags {
        display: inline-flex;
        min-height: 2.5em !important;
        padding: 5px 40px 4px 10px;
    }
    .multiselect__select {
        height: 2.5em !important;
        padding: 8px;
    }
    .multiselect__select:before {
        border-color: $link transparent transparent;
    }
    .multiselect__option.multiselect__option--highlight {
        background-color: $genesis-blue;
    }
    i.far:hover {
        color: black;
    }
    i.far, i.fas {
        margin: 0 2px;
    }
    i.fas:hover {
        cursor: default;
    }
}