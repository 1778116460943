// Fonts
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700|Inconsolata&display=swap");

// Variables
@import "variables.scss";

@import '~@progress/kendo-theme-default/dist/all.scss';

@import "~bulma/sass/utilities/_all.sass";

@import 'genesis-vue.scss';

@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/helpers/_all.sass";
@import "~bulma/sass/elements/_all.sass";
@import "~bulma/sass/form/_all.sass";
@import "~bulma/sass/components/_all.sass";
@import "~bulma/sass/grid/_all.sass";
@import "~bulma/sass/layout/_all.sass";

// Bulma extensions
@import 'bulma-switch';
@import 'bulma-checkradio';
@import '~@creativebulma/bulma-badge';

// Buefy
@import '~buefy/src/scss/buefy.scss';

// leaflet
@import '~leaflet/dist/leaflet.css';

@import 'ag-grid-variables.scss';

// ag-grid
@import "~@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "~@ag-grid-community/core/dist/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss";

.ag-theme-balham {
  @include ag-theme-balham((
    /* Font */
          font-family: $family-sans-serif,
          font-size: $size-6,
          secondary-foreground-color: $grey-dark,
    /* Header */
          header-background-color: $grey-lighter,
    /* Cell */
          border-color: $grey,
          background-color: $grey-lightest,
          foreground-color: $table-color,
          odd-row-background-color: $white-ter,
          selected-row-background-color: $selected-row-color,
          cell-horizontal-padding: ag-derived(grid-size, $times: .5),
  ))
}

// Vue select
@import "~vue-select/src/scss/vue-select.scss";

// Styles
@import "styles.scss";
@import "grid_configuration_dropdown.scss";
@import "spacing.scss";

// Multiselect
@import "~vue-multiselect/dist/vue-multiselect.min.css";

// FontAwesome
@import "~@fortawesome/fontawesome-pro/css/all.css";


// vue2 datepicker
@import '~vue2-datepicker/scss/index.scss';

