
$complete: hsl(120, 47%, 85%);
$complete-dark: hsl(120, 20%, 66%);

$false: hsl(28, 77%, 84%);
$false-dark: hsl(28, 33%, 70%);

$cancelled: hsl(0, 0%, 78%);
$cancelled-dark: hsl(32, 56%, 56%);

$full-inspection: hsl(0, 71%, 80%);
$full-inspection-dark: hsl(0, 33%, 65%);

$selected-row-color: hsl(228, 100%, 86%);