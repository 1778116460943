/* Body */
$body-bg: $white-ter;
$body-background-color: $white-ter;

/* Navbar */
$navbar-height: 2.3rem;

/* Buefy */
$toast-border-radius: .5em;

/* Panels */
$panel-default-heading-bg: $white;

/* Toolbar */
$toolbar-height: 4rem !default;

/* hr */
$hr-background-color: hsl(0, 0%, 77%) !default;
$hr-height: 1px !default;
$hr-margin: 0 0 1rem 0 !default;

/* Tabs */
$tabs-boxed-link-active-background-color: $grey-lightest;

/* V-select */
$vs-border-color: $grey-lighter;
$vs-component-line-height: 1.75em;
$vs-component-placeholder-color: $grey-light;
$vs-controls-color: $primary;
$vs-dropdown-box-shadow: 0 0 0 0.125em rgba(75, 94, 149, 0.25);
$vs-state-active-bg: $primary;

/* Bulma-checkradio */
$checkbox-border: .1rem solid $grey-lighter;

/* Bulma-input */
$input-disabled-background-color: $grey-lightest;
$input-disabled-border-color: $grey-lighter;


.dashboard-widget {
  border-radius: 4px;
  position: relative;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  background-color: rgba(75, 94, 149, 0.09);
}