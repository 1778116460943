html {
    height: 100%;
    overflow: auto;
}
.is-height100pct,
.grid-container {
    height: 100%;
}

.freight-movement, .load-tender, .freight {
    .grid-container {
        height: calc(100% - #{$toolbar-height});
    }
}

/* Ag-grid */
.ag-header-cell-text, .cell-wrap-text {
    white-space: normal;
}

.ag-row.row-status-complete:not(.ag-row-selected) {
    background-color: $complete;
}

.ag-row.row-status-false:not(.ag-row-selected) {
    background-color: $false;
}

.ag-row.row-status-in-cancelled:not(.ag-row-selected),
.ag-row.row-status-cancelled:not(.ag-row-selected) {
    background-color: $cancelled;
}

.ag-row.row-status-in-customs-full-inspection:not(.ag-row-selected),
.ag-row.row-status-in-customs-mex-full-inspection:not(.ag-row-selected) {
    background-color: $full-inspection;
}

.ag-row.row-status-in-customs-full-inspection-2:not(.ag-row-selected),
.ag-row.row-status-in-customs-usa-full-inspection:not(.ag-row-selected) {
    background-color: $full-inspection;
}

/* Toolbar section */
.toolbar {
    height: $toolbar-height;
}

/* Multiselect style */
.level .multiselect,
.level .multiselect__input,
.level .multiselect__single {
    font-size: $size-7;
}

.multiselect,
.multiselect__input,
.multiselect__single {
    font-size: $size-6;
}

.multiselect__option--highlight {
    background: $info;
    color: $white;
    outline: none;
}

.multiselect__option--highlight:after {
    background: $info;
    color: $white;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: $grey-light;
    color: $white;
}

.multiselect__option--selected.multiselect__option--highlight:after {
    background: $grey-light;
    color: $white;
}

/* TwoPanelMultiselect component */
.tpm__select {
    min-height: 9.375em;
}

/* Datepicker */
.mx-calendar {
    table td:not([align]), table th:not([align]) {
        text-align: center;
    }
}
.date-range .mx-datepicker {
    width: 7rem;
}

/* Notification */
.notification.is-primary-light {
    background-color: $genesis-blue-light;
}

/* Link */
.is-link {
    color: $link;
}

.is-link:hover {
    color: $link-hover;
    cursor: pointer;
}

/* Vue-select */
.v-select .vs__dropdown-toggle {
    background: $input-background-color;
}

.v-select.vs--open .vs__dropdown-toggle,
.v-select.vs--open .vs__dropdown-toggle:hover {
    border-color: $input-focus-border-color;
    box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
}

.v-select .vs__dropdown-toggle:hover {
    border-color: $border-hover;
}

.v-select .vs__dropdown-menu {
    top: calc(100% + #{$border-width});
    border-color: $input-focus-border-color;
}

/* Webkit remove */
.webkit-remove-number.input[type="number"]::-webkit-outer-spin-button,
.webkit-remove-number.input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.webkit-remove-number.input[type="number"] {
    -moz-appearance: textfield;
}
