@use "sass:math";

/* Bulma */
$button-focus-box-shadow-size: 0 0 0 .25em;
$section-padding: 1rem 1.5rem;
$section-padding-desktop: 1rem 1.5rem;

/* Colors */
$genesis-blue: hsl(225, 33%, 44%);
$genesis-blue-light: hsla(225, 33%, 44%, 0.09);
$primary: $genesis-blue;
$link: $genesis-blue;

/* Typography */
$family-sans-serif: "Source Sans Pro", sans-serif;
$family-monospace: "Inconsolata", monospace;

$size-1: 2.75rem;
$size-2: 2.25rem;
$size-3: 1.75rem;
$size-4: 1.25rem;
$size-5: 1rem;
$size-6: 0.88rem;
$size-7: 0.76rem;
$size-8: 0.69rem;

$body-font-size: $size-6;
$small-font-size: $size-7;

/* Level */
$level-item-spacing: math.div(0.5rem, 2);

/* Vue-select */
$vs-dropdown-max-height: 250px;

/* kendo */
$font-size: 12px;
$table-cell-padding-x: 2px;
$table-cell-padding-y: 2px;
$table-cell-padding: 2px;
